import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StyledQuickText } from './quickText.css.js';

const QuickText = () => {
	const data = useStaticQuery(graphql`
		query QuickTextQuery {
			homeJson {
				quickText {
					childMarkdownRemark {
						html
						rawMarkdownBody
					}
				}
			}
		}
	`);
	return (
		<StyledQuickText>
			<div className="container">
				<p>{data.homeJson.quickText.childMarkdownRemark.rawMarkdownBody}</p>
			</div>
		</StyledQuickText>
	);
};

export default QuickText;
