import styled from 'styled-components';

export const StyledProfile = styled.section`
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;

	.padded {
		padding: 15px 10px 15px 10px;
	}
`;
