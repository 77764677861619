import styled from 'styled-components';

export const StyledBio = styled.section`
	margin-top: 45px;

	.image {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.padded {
		padding: 15px 10px 15px 10px;
		text-align: center;
	}

	.text {
		p {
			margin-bottom: 1rem;
		}

		a {
			font-size: inherit;
			font-style: italic;
		}
	}
`;
