import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledQuickText = styled.section`
	padding-bottom: 30px;

	${MEDIA.MIN_PHONE`
		padding-bottom: 50px;
		`}
`;
