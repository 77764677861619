import React, { useState, useEffect } from 'react';
import { StyledReviews, QuoteBox, ReviewText } from './reviews.css';
import { useStaticQuery, graphql } from 'gatsby';
import { PoseGroup } from 'react-pose';

const Rates = () => {
	const data = useStaticQuery(graphql`
		query ReviewsQuery {
			homeJson {
				reviews
			}
		}
	`);

	const [count, setCount] = useState(
		Math.floor(Math.random() * 10) % data.homeJson.reviews.length
	);
	const [isVisible, setVisible] = useState(true);

	useEffect(() => {
		const interval = setInterval(() => {
			setVisible(false);
			setTimeout(
				() => setCount((count) => (count + 1) % data.homeJson.reviews.length),
				500
			);
			setTimeout(() => setVisible(true), 1500);
		}, 12000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="container">
			<StyledReviews>
				<h3>
					Here are some things students have found valuable about our lessons:
				</h3>
				<QuoteBox>
					<h1 className="startquote">&quot;</h1>
					<div className="flexer">
						<PoseGroup>
							{isVisible && (
								<ReviewText key="review">
									<p>{data.homeJson.reviews[count]}</p>
								</ReviewText>
							)}
						</PoseGroup>
					</div>
					<h1 className="endquote">&quot;</h1>
				</QuoteBox>
			</StyledReviews>
		</div>
	);
};

export default Rates;
