import React from 'react';
import Img from 'gatsby-image';
import { StyledProfile } from './profile.css';
import { useStaticQuery, graphql } from 'gatsby';

const Profile = () => {
	const data = useStaticQuery(graphql`
		query ProfilePictureQuery {
			homeJson {
				profilePicture {
					childImageSharp {
						fluid(maxWidth: 705, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				pictureAlt
				caption
			}
		}
	`);
	return (
		<StyledProfile>
			<Img
				fluid={
					data.homeJson.profilePicture
						? data.homeJson.profilePicture.childImageSharp.fluid
						: {}
				}
				alt={data.pictureAlt}
			/>
			<div className="padded container">
				<i>{data.homeJson.caption}</i>
			</div>
		</StyledProfile>
	);
};

export default Profile;
