import React from 'react';
import { StyledContact } from './contact.css';
import { useStaticQuery, graphql } from 'gatsby';
import Obfuscate from 'react-obfuscate';

const Contact = () => {
	const data = useStaticQuery(graphql`
		query ContactQuery {
			homeJson {
				acceptNewStudents
				phone
			}
		}
	`);
	const textA =
		data.homeJson.acceptNewStudents === 'y'
			? 'Free 15-minute discovery call'
			: 'Not accepting students';
	const textB =
		data.homeJson.acceptNewStudents === 'y'
			? 'Limited enrollment available'
			: 'Enrollment currently full';
	return (
		<StyledContact id="contact">
			<div className="container">
				<h2 className="a">{textA}</h2>
				<h3 className="b">{textB}</h3>
				<section className="phone-section">
					<span className="phone-icon" />
					<Obfuscate tel={data.homeJson.phone} />
				</section>
			</div>
		</StyledContact>
	);
};

export default Contact;
