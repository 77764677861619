import React from 'react';
import Img from 'gatsby-image';
import { StyledBio } from './bio.css';
import { useStaticQuery, graphql } from 'gatsby';

const Bio = () => {
	const data = useStaticQuery(graphql`
		query BioQuery {
			homeJson {
				bioPicture {
					childImageSharp {
						fluid(maxWidth: 705, quality: 90) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				bioPictureAlt
				bio {
					childMarkdownRemark {
						html
						rawMarkdownBody
					}
				}
			}
		}
	`);
	const text = data.homeJson.bio.childMarkdownRemark.html;
	return (
		<StyledBio>
			<Img
				className="image"
				fluid={
					data.homeJson.bioPicture
						? data.homeJson.bioPicture.childImageSharp.fluid
						: {}
				}
				alt={data.bioPictureAlt}
			/>
			<div className="padded container">
				<i>
					Ryan Ehresman performing live at
					<a href="http://1015.com/"> 1015 Folsom</a>, San Francisco&lsquo;s
					biggest nightclub
				</i>
			</div>
			<div
				className="container text"
				dangerouslySetInnerHTML={{ __html: text }}
			></div>
		</StyledBio>
	);
};

export default Bio;
