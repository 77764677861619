import React from 'react';
import { StyledRates } from './rates.css';
import { useStaticQuery, graphql } from 'gatsby';

const Rates = () => {
	const data = useStaticQuery(graphql`
		query RatesQuery {
			homeJson {
				rateInfo {
					childMarkdownRemark {
						html
						rawMarkdownBody
					}
				}
				instruments
				adultRate
				kidRate
				semesterKidRate
				semesterAdultRate
			}
		}
	`);

	return (
		<div className="container">
			<StyledRates>
				<h5>Rates</h5>
				<i className="small">Virtual lessons also available!</i>
				<p className="info">
					{data.homeJson.rateInfo.childMarkdownRemark.rawMarkdownBody}
				</p>
				<div className="lesson-table">
					<div className="row">
						<div>Monthly Tuition (Ages 5-12)</div>
						<div>{data.homeJson.semesterKidRate}</div>
					</div>
					<div className="row">
						<div>Monthly Tuition (Ages 12+)</div>
						<div>{data.homeJson.semesterAdultRate}</div>
					</div>

					<div className="row">
						<div>Individual Lesson (Ages 5-12)</div>
						<div>{data.homeJson.kidRate}</div>
					</div>

					<div className="row">
						<div>Individual Lesson (Ages 12+)</div>
						<div>{data.homeJson.adultRate}</div>
					</div>

					<div className="row">
						<div>Piano Tuning</div>
						<div>175 (est)</div>
					</div>
				</div>
			</StyledRates>
		</div>
	);
};

export default Rates;
