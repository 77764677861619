import React, { useState, useEffect } from 'react';
import BREAKPOINTS from 'constants/breakpoints';

const Video = (props) => {
	const defaultVideoWidth = 703;
	const [width, setWidth] = useState(defaultVideoWidth);

	useEffect(() => {
		const windowWidth = window.visualViewport
			? window.visualViewport.width
			: window.innerWidth;
		if (windowWidth < BREAKPOINTS.TABLET) {
			setWidth(windowWidth);
		}
	}, []);

	const height = (width * 9) / 16;

	return (
		<div className="video-container">
			<iframe
				title="Intro Video"
				width={width}
				height={height}
				src={props.link}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default Video;
