import styled from 'styled-components';

export const Container = styled.header`
	padding-top: 3rem;
	text-align: center;

	.right {
		left: 40px;
		top: 5px;
		position: relative;
		margin-bottom: 20px;
	}
`;
