import styled from 'styled-components';

export const StyledRates = styled.section`
	text-align: center;
	margin-top: 75px;
	margin-left: auto;
	margin-right: auto;

	.info {
		text-align: left;
		margin-top: 20px;
	}

	.lesson-table {
		max-width: 375px;
		margin-left: auto;
		margin-right: auto;
	}

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.small {
		margin-top: 20px;
		font-size: 15px;
	}

	.note {
		margin-top: 20px;
		text-align: center;
	}
`;
