import React from 'react';
import { Container } from './title.css';
import { useStaticQuery, graphql } from 'gatsby';

const Title = () => {
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			homeJson {
				title
				subtitle
			}
		}
	`);
	return (
		<div className="container">
			<Container>
				<h1>{data.homeJson.title}</h1>
				<h6 className="right">{data.homeJson.subtitle}</h6>
			</Container>
		</div>
	);
};

export default Title;
