import React from 'react';
import Layout from 'components/layout';
import Title from 'components/title';
import Profile from 'components/profile';
import QuickText from 'components/quickText';
import Video from 'components/video';
import Reviews from 'components/reviews';
import Contact from 'components/contact';
import Rates from 'components/rates';
import SocialMedia from 'components/socialMedia';
import Bio from 'components/bio';
import { useStaticQuery, graphql } from 'gatsby';

const Index = () => {
	const data = useStaticQuery(graphql`
		query IndexQuery {
			homeJson {
				video1URL
				video2URL
			}
		}
	`);
	return (
		<Layout>
			<Title />
			<hr className="hide-mobile" />
			<Profile />
			<QuickText />
			<Video link={data.homeJson.video1URL} />
			<Reviews />
			<hr />
			<Contact />
			<hr />
			<Bio />
			<Rates />
			<Video link={data.homeJson.video2URL} />
			<SocialMedia />
		</Layout>
	);
};

export default Index;
