import styled from 'styled-components';

export const StyledSocialMedia = styled.section`
	text-align: center;
	margin-top: 75px;
	margin-bottom: 75px;
	margin-left: auto;
	margin-right: auto;
	max-width: 375px;
`;
