import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { main, dark } from 'constants/theme';
import posed, { PoseGroup } from 'react-pose';

export const StyledReviews = styled.section`
	text-align: center;
	margin-top: 75px;

	.row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.small {
		margin-top: 20px;
		font-size: 15px;
	}

	.note {
		margin-top: 20px;
		text-align: center;
	}
`;

export const QuoteBox = styled.section`
	margin-top: 25px;
	text-align: left;
	background-image: linear-gradient(45deg, ${dark},${main});

  border-radius: 7px;
	color: white;

	h1 {
		color: white;
		font-size: 100px;
		height: 85px;
	}

	.flexer {
		justify-content: center;
		align-items: center;
		display: flex;
		height: 170px;
	}
	p {
		text-align: center;
		margin-left: 20px;
    margin-right: 20px;
    font-style: italic;
	}
	.startquote {
		margin-left: 0px;
	}

	.endquote {
		text-align: right;
		margin-right: 20px;
	}

	${MEDIA.MIN_PHONE`
		p {
			margin-left: 40px;
    margin-right: 40px;
		}
		`}


	.startquote {
		margin-left: 10px;
	}

	.endquote {
		text-align: right;
		margin-right: 30px;
	}

	}
`;

export const ReviewText = posed.div({
	enter: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: 300 },
});
