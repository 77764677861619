import React from 'react';
import { StyledSocialMedia } from './socialMedia.css';
import { useStaticQuery, graphql } from 'gatsby';

const SocialMedia = () => {
	const data = useStaticQuery(graphql`
		query SocialMediaQuery {
			homeJson {
				instagram
				youtube
			}
		}
	`);

	return (
		<div className="container">
			<StyledSocialMedia>
				<div>
					Find my work on
					<a href={data.homeJson.youtube}> YouTube </a>
					:)
				</div>
			</StyledSocialMedia>
		</div>
	);
};

export default SocialMedia;
