import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { dark } from 'constants/theme';

export const StyledContact = styled.section`
	text-align: center;
	margin-top: 30px;
	font-size: 28px;

	.phone-icon{
		margin-right: 12px;
		display: inline-block;
		width: 20px;
		height: 43px;
		background-color: ${dark}
		border-radius: 3px;
		transform: rotate(15deg) translateY(14px);
	}

	.phone-section{
		margin-top: 60px;
		
	}

	${MEDIA.MIN_PHONE`
		margin-top: 55px;
		`}
`;
